.App {
  background:
        linear-gradient(red, transparent),
        linear-gradient(to top left, lime, transparent),
        linear-gradient(to top right, blue, transparent);
    background-blend-mode: screen;
}

.content {
  font-family: "Syne", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  min-height: 100vh;
  padding: 40px;
  text-align: right;
  font-size: 8vw;
  line-height: 1.1em;
  @media screen and (max-width: 500px) {
    font-size: 10vw; 
    padding: 20px;
  }
}
.bold {
  font-weight: 700;
  text-shadow: 6px 6px 0px #FBFF4B;
  @media screen and (max-width: 800px) {
    text-shadow: 3px 3px 0px #FBFF4B;
  }
}
.boldblue {
  font-weight: 700;
  text-shadow: 6px 6px 0px #4bb1ff;
  @media screen and (max-width: 800px) {
    text-shadow: 3px 3px 0px #4bb1ff;
  }
}
.boldred {
  font-weight: 700;
  text-shadow: 6px 6px 0px #ff5b8c;
  @media screen and (max-width: 800px) {
    text-shadow: 3px 3px 0px #ff5bce;
  }
}